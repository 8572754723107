<template>
  <div class="list">
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <!-- <el-select v-model="status" clearable placeholder="全部状态" @change="onSearch">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select> -->
        <el-input v-model="search" placeholder="请输入商超名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column prop="number" label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column prop="shopName" label="商超名称" width="150" show-overflow-tooltip></el-table-column>
          <el-table-column label="营业执照" width="110">
            <template slot-scope="scope">
              <span class="show_img" @click="previewImage(scope.row.businessLicenseUrl)">预览图片</span>
            </template>
          </el-table-column>
          <el-table-column prop="creditCode" label="统一社会信用代码" min-width="150" show-overflow-tooltip></el-table-column>
          <el-table-column prop="schoolName" label="关联学校" min-width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="contactMan" label="联系人" show-overflow-tooltip></el-table-column>
          <el-table-column prop="contactTel" label="联系电话" show-overflow-tooltip></el-table-column>
          <el-table-column prop="address" label="商超地址" show-overflow-tooltip>
            <template slot-scope="scope">
              <span class="desc">{{ scope.row.province }}{{ scope.row.city }}{{ scope.row.area }}{{ scope.row.address }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="简介" width="120" show-overflow-tooltip>
            <template slot-scope="scope">
              <span class="desc">{{ scope.row.introduction }}</span>
            </template>
          </el-table-column> -->
          <el-table-column prop="status" label="审核状态">
            <template slot-scope="scope">
              <span class="desc">{{ scope.row.status == 0 ? "待审核" : scope.row.status == 1 ? "审核通过" : scope.row.status == 2 ? "审核不通过" : "审核通过" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="登录状态">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.switch" active-color="#069BBC" inactive-color="#E2E2E2" @change="changeStatus(scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="商城状态">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.mallSwitch" active-color="#069BBC" inactive-color="#E2E2E2" @change="onStatus(scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" fixed="right" width="200px">
            <template slot-scope="scope">
              <el-button class="btn" type="primary" size="small" @click="onShopNumber(scope.row)" v-if="scope.row.merId==null">绑定商户号</el-button>
              <el-button class="btn" type="primary" size="small" plain @click="onShopNumber(scope.row)" v-else>修改商户号</el-button>
              <el-button class="btn" type="primary" size="small" plain @click="showDetail(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
    <el-dialog :title="title" :visible.sync="submitVisible" width="50%" :modal="true" :close-on-click-modal="false">
      <el-form ref="examineForm" class="examineForm" :rules="rules" :model="examineForm" label-position="left" label-width="">
        <el-form-item label="商超名称：">
          <el-input v-model="examineForm.shopName" disabled></el-input>
        </el-form-item>
        <el-form-item label="商 户 号 ：" prop="merId">
          <el-input v-model="examineForm.merId" placeholder="只能输入英文或数字"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="onCancel('examineForm')">取 消</el-button>
        <el-button type="primary" @click="submitForm('examineForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog>

    <el-image-viewer v-if="showViewer" :on-close="
        () => {
          showViewer = false
        }
      " :url-list="imgList"></el-image-viewer>
  </div>
</template>

<script>
export default {
  components: {
    "el-image-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    return {
      title: "绑定商户号",
      loading: false,
      submitVisible: false,
      search: "",
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      submitLoding: false,
      options: [
        {
          value: "0",
          label: "待审核",
        },
        {
          value: "1",
          label: "审核通过",
        },
        {
          value: "2",
          label: "审核不通过",
        },
      ],
      status: "",

      // 图片预览
      showViewer: false,
      imgList: [],
      examineForm: {
        shopName: "",
        merId: '',
        shopId: '',
      },
      rules: {
        merId: [
          { required: true, message: "只能输入英文或数字", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              if (/^[A-Za-z0-9\u4e00-\u9fa5]{4,30}$/.test(value) == false) {
                callback(new Error("只能输入英文或数字"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur"
          }
        ],
      },
    }
  },
  created() {
    this.getSupplierInfoPageList()

  },
  methods: {
    onShopNumber(row) {
      this.submitVisible = true
      let data = Object.assign({}, row)
      this.examineForm.shopName = data.shopName
      this.examineForm.shopId = data.shopId
      this.examineForm.merId = data.merId
      if (data.merId != null) {
        this.title = "修改商户号"
      } else {
        this.title = "绑定商户号"
      }
    },
    onChangeNumber() { },
    // 关闭对话框
    onCancel(formName) {
      this.submitVisible = false;
      this.$refs[formName].resetFields();
    },
    // 提交表单
    submitForm(formName) {
      this.submitLoding = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post(this.$api.insertShopMerId, this.examineForm).then((res) => {
            if (res.data.code == 100) {
              this.$message.success(res.data.desc);
              this.getSupplierInfoPageList();
              this.onCancel();
              this.submitLoding = false;
            }
          }).catch(() => {
            this.submitLoding = false;
            // this.onCancel();
          });
        } else {
          this.$message.error("提交失败");
          this.submitLoding = false;

          return false;
        }
        this.submitLoding = false;
      });
    },
    // 商超列表
    getSupplierInfoPageList() {
      this.loading = true
      this.$axios
        .get(this.$api.shopInfoPage, {
          params: {
            shopName: this.search,
            page: this.currentPage,
            pageSize: this.pageSize,
            //  status: 1, // 审核状态 ( 0:待审核 1:审核通过 2:审核不通过 )
          },
        })
        .then(res => {
          let list = res.data.result.list.map(e => {
            return {
              ...e,
              switch: e.startStatus == "1",
              mallSwitch: e.mallStatus == "1",
            }
          })
          this.tableData = list
          this.totalItemsCount = res.data.result.totalCount
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 搜索
    onSearch() {
      this.tableData = []
      this.currentPage = 1
      this.getSupplierInfoPageList()
    },
    // 切换登录状态
    changeStatus(row) {
      let item = Object.assign({}, row)
      const originalSwitch = item.switch
      this.$confirm(`该商超登录即将${item.switch ? "启用" : "停用"}，是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .put(this.$api.shopChange, {
              shopId: item.shopId,
              startStatus: item.switch ? "1" : "0",
            })
            .then(() => {
              this.$set(item, "switch", originalSwitch)
              this.$message.success("操作成功")
              this.getSupplierInfoPageList()
            })
            .catch(() => {
              this.$set(item, "switch", !originalSwitch)
              // this.$message.error("操作失败");
              this.getSupplierInfoPageList()
            })
        })
        .catch(() => {
          this.$set(item, "switch", !originalSwitch)
          this.getSupplierInfoPageList()
          this.$message.warning("操作取消")
        })
    },
    // 切换登录状态
    onStatus(row) {
      let item = Object.assign({}, row)
      const originalSwitch = item.mallSwitch
      this.$confirm(`该商超即将${item.mallSwitch ? "启用" : "停用"}，是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .put(this.$api.updateShopMallStatus, {
              shopId: item.shopId,
              mallStatus: item.mallSwitch ? "1" : "0",
            })
            .then(() => {
              this.$set(item, "mallSwitch", originalSwitch)
              this.$message.success("操作成功")
              this.getSupplierInfoPageList()
            })
            .catch(() => {
              this.$set(item, "mallSwitch", !originalSwitch)
              // this.$message.error("操作失败");
              this.getSupplierInfoPageList()
            })
        })
        .catch(() => {
          this.$set(item, "mallSwitch", !originalSwitch)
          this.getSupplierInfoPageList()
          this.$message.warning("操作取消")
        })
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e
      this.getSupplierInfoPageList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getSupplierInfoPageList()
    },
    // 查看
    showDetail(row) {
      this.$router.push({ path: "/supermarket/detail/info", query: { id: row.shopId } })
    },
    // 图片预览
    previewImage(url) {
      this.imgList[0] = url
      this.showViewer = true
    },
  },
}
</script>

<style scoped lang="scss">
.list {
  .examineForm {
    ::v-deep .el-form-item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    ::v-deep .el-form-item__content {
      margin-left: 10px !important;
    }
    ::v-deep .el-input {
      width: 255px !important;
    }
    ::v-deep .el-input-number {
      width: 255px !important;
    }
  }
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      margin-left: 20px;
      width: 300px;
    }

    .el-button {
      margin-left: 20px;
    }
  }

  .show_img {
    color: #069bbc;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
